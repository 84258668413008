import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Images from '../components/images'
import AudioPlayer from "react-h5-audio-player"

import waves from "../static/ocean-waves.mp3"

const Player = () => (
  <AudioPlayer
    loop
    src={waves}
    onPlay={e => console.log("onPlay")}
    // other props here
  />
);

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{ marginBottom: `1.45rem` }}>
      <Image />
    </div>
    <h2 style={{
      color: `white`,
      fontSize: `3.5rem`
      }}>Waves</h2>
      <p>Passout with Wyn, listen to the soothing ocean.</p>
    <Player/>
    <Images />
  </Layout>
)

export default IndexPage
