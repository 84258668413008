import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image"

const Images = () => {
    const data = useStaticQuery(graphql`
    {
      jpg: allDropboxNode(filter: {localFile: {extension: {eq: "jpg"}}}) {
          edges {
            node {
              path
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                    originalName
                    srcWebp
                  }
                }
              }
            }
          }
        }
        gif: allDropboxNode(filter: {localFile: {extension: {eq: "gif"}}}) {
          edges {
            node {
              localFile {
                name
                publicURL
                
              }
            }
          }
        }
      }

    `)
  
    return (
        <React.Fragment>
            <h2 style={{
                color: `white`,
                fontSize: `3.5rem`
                }}>Pics</h2>
            <p>Click the pic to download for yourself <span role="img" aria-label="dancing">💃</span></p>
      <div className="wyn-images-container">
          {data.jpg.edges.map((jpg, index ) => {
              return (
                <div key={ index }>
                    <a download={ jpg.node.localFile.childImageSharp.fluid.originalName } href={ jpg.node.localFile.childImageSharp.fluid.srcWebp } title="Click to download">
                      <Img className="polygon-shape" fluid={jpg.node.localFile.childImageSharp.fluid} alt="this is some alt text" />
                    </a>
                    
                </div>
              )
        })}
        {data.gif.edges.map((gif, index ) => {
              return (
                <div key={ index }>
                    <a download={ gif.node.localFile.name } href={ gif.node.localFile.publicURL } title="Click to download">
                      <img className="polygon-shape" src={gif.node.localFile.publicURL } alt="this is some alt text" />
                    </a>
                    
                </div>
              )
        })}
      </div>
      </React.Fragment>
    );

  }

export default Images;